import { Box, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { db } from '../../firebase/database'
import { useFetchCursor } from '../../firebase/hooks/useFetchCursor'
import { OrderCard } from '../../pages/myorders/MyOrders'

const useStyles = makeStyles((theme) => ({
  storeMainBox: {
    border: '1px solid #ccc',
    borderRadius: 5,
    margin: '1rem',
  },
  headerDetail: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: '#F1F3F2',
  },
  datosEnvioWrap: {
    width: '60%',
  },
  datosEnvioInfo: {
    justifyContent: 'flex-start',
  },

  datosEnvioInfoText: {
    margin: 0,
  },
  datosEnvioInfoDateText: {
    margin: 0,
    fontWeight: 'bold',
  },
  contentDetailBox: {
    backgroundColor: '#fff',
    padding: '.5rem',
  },
  datosNombreStore: {
    margin: 0,
    fontSize: 20,
  },
  datosNombreText: {
    margin: 0,
    fontSize: 15,
  },

  footerDetail: {
    borderTop: '1px solid #ccc',
    backgroundColor: '#fff',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  skeletonBox: {
    width: 300,
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footerText: {
    margin: 0,
    padding: '.5rem',
  },
}))

export const StoreOrders = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [cursor, setCursor] = useState(true)
  const [orders, isLoading, isEnd] = GetOrders(cursor)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <Box>
      {loading
        ? orders.map((order, i) => (
          <Box key={i}
            pt={0.8}
            className={classes.skeletonBox}>
            <Skeleton
              animation="wave"
              variant="rect"
              height={120}
              width="100%"
            />{' '}
            <Skeleton width="100%" />
          </Box>
        ))
        : orders.map((order) => <OrderCard order={order}
          key={order.id} />)}
      {!isEnd && orders?.length ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => setCursor(!cursor)}
            variant="contained"
            style={{ marginBottom: 10 }}
            color="primary"
          >
            {isLoading ? <CircularProgress /> : 'Ver más'}
          </Button>
        </div>
      ) : (
        <Box style={{ margin: 'auto', marginTop: 100 }}>
          {isLoading && <CircularProgress />}
        </Box>
      )}
    </Box>
  )
}

function GetOrders(cursor) {
  const history = useHistory()
  const locId = history.location.pathname.split('/').pop()

  const { data: orders, isLoading, isEnd } = useFetchCursor({
    query:
      db.collection('orders')
        .where('store', '==', locId)
        .orderBy('timestamp', 'desc')
    ,
    cursor,
    enabled: locId
  })


  return [orders, isLoading, isEnd]
}
