import { useEffect } from 'react'
import { useState } from 'react'

export const useFetchCursor = ({ cursor, query, enabled }) => {
  const [isEnd, setIsEnd] = useState(false)
  const [data, setData] = useState([])
  const [lastVisited, setLastVisited] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const firstFetch = async () => {
    let active = true

    await query
      .limit(10)
      .get()
      .then((docs) => {
        let ndocs = []
        const lastVisible = docs.docs[docs.docs.length - 1]
        setLastVisited(lastVisible)

        docs.forEach(function (doc) {
          let data = doc.data()
          ndocs.push({ ...data, id: doc.id })
        })

        active && setData(ndocs)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (!enabled) return

    let active = true
    setIsLoading(true)

    if (!lastVisited && !isEnd) {
      firstFetch()
      return
    }

    if (lastVisited && !isEnd) {
      query
        .startAfter(lastVisited)
        .limit(10)
        .get()
        .then((docs) => {
          let ndocs = data
          const lastVisibleDocs = docs.docs[docs.docs.length - 1]
          if (lastVisibleDocs) {
            setLastVisited(lastVisibleDocs)
          }

          // Fetchea por segunda vez para revisar si quedan más elementos tomando como referencia el último elemento
          query
            .startAfter(lastVisibleDocs)
            .limit(10)
            .get()
            .then((docs2) => {
              const lastVisible = docs2.docs[docs2.docs.length - 1]
              if (!lastVisible) {
                setIsEnd(true)
              }
            })

          docs.forEach(function (doc) {
            let data = doc.data()
            ndocs.push({ ...data, id: doc.id })
          })

          setIsLoading(false)
          active && setData(ndocs)
        })
    }

    return () => (active = false)
  }, [cursor, enabled])

  return { cursor, isEnd, isLoading, data }
}
