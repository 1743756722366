import { Avatar, Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'

import imgurWebp from '../../components/Utils/imgurWebp'
import { useCollection } from '../../firebase/database'
import { formatTextToUrl } from '../../lib/formatTextToUrl'

const LIMIT = 10

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: 'pointer',

    minWidth: '100px',
    height: '100px',
    '&:hover': {
      opacity: 0.8,
    },
  },
  avatarSelected: {
    border: '1px solid black',
    '&:hover': {
      opacity: 1,
      cursor: 'auto',
    },
  },
  weightCard: {
    border: '1px solid rgba(0,0,0,0.1)',
    // maxWidth: '100px',
    borderRadius: 6,
    padding: '2px 4px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
    marginTop: 3,
  },
  weightCardSelected: {
    border: '1px solid black',
    borderRadius: 6,
    padding: '2px 4px',
    '&:hover': {
      opacity: 1,
      cursor: 'auto',
    },
  },
  weight: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
}))

export const Variants = ({ product }) => {
  const { name, brand, objectID, taste, quantity } = product
  const classes = useStyles()
  const isDesktop = useMediaQuery('(min-width:1520px)')

  const [products, isLoading] = useCollection({
    collection: 'products',
    where: [
      ['brand', '==', brand],
      ['name', '==', name],
      ['active', '==', true],
    ],
    limit: LIMIT,
  })

  const variantsInTaste = products

  const isEmptyVariantInTaste = !variantsInTaste?.length || variantsInTaste.length === 1
  const isSomeVariantWithDiffInQuantity = quantity && products?.some((variant) => variant.quantity !== quantity)
  const isSomeVariantInTaste = quantity && products?.some((variant) => variant.taste && variant.taste !== taste)

  if (isLoading) {
    return null
  }

  return (
    <>
      {!isEmptyVariantInTaste && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, overflow: 'auto', width: '100%', ...(isDesktop ? { flexWrap: 'wrap' } : {}) }}>
          {variantsInTaste.map((variant) => (
            <Link
              key={variant.objectID}
              className={classes.link}
              to={`/app/product/${formatTextToUrl(variant?.name) + '-' + formatTextToUrl(variant?.taste)}/${variant.objectID}`}
            >
              <Avatar
                className={[classes.avatar, objectID === variant.objectID ? classes.avatarSelected : '']}
                variant="square"
                alt={variant?.taste || name}
                src={imgurWebp(variant?.image, 120)}
                style={{ borderRadius: 6 }}
              />
              {(!!isSomeVariantWithDiffInQuantity || !!isSomeVariantInTaste) && (
                <Box className={[classes.weightCard, objectID === variant.objectID ? classes.weightCardSelected : '']}>
                  <Typography className={classes.weight}>
                    {!!isSomeVariantInTaste && variant.taste}
                    {!!isSomeVariantInTaste && !!isSomeVariantWithDiffInQuantity ? ' - ' : ''}
                    {!!isSomeVariantWithDiffInQuantity && `${variant.quantity}${variant.unit}`}
                  </Typography>
                </Box>
              )}
            </Link>
          ))}
        </Box>
      )}
    </>
  )
}
