//react
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//material
import { Box, Chip, Typography, withStyles } from '@material-ui/core'
import { Input } from '@material-ui/core'
import { useMediaQuery } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { floor } from 'lodash'
// import { PriceSlider } from "./PriceSlider";
//libreria
import matchSorter from 'match-sorter'
import React, { useEffect, useState } from 'react'

import AddParam from '../../../components/Layout/LocationComponent'
import imgurWebp from '../../../components/Utils/imgurWebp'
import config from '../../../config/config'
//logica, estado y componentes
import { useMarketContext } from '../../../context/MarketContext'
import { usePriceListIDContext } from '../../../context/PriceListContext'
import { useProductsState } from '../../../context/ProductsContext'
import { useSelectedStore, useUserData } from '../../../context/UserContext'
//styles
import useStyles from './styles'

export default function Filters() {

    const minw1024 = useMediaQuery('(min-width:1024px)')
    const minw450 = useMediaQuery('(min-width:450px)')
    const minw400 = useMediaQuery('(min-width:400px)')
    const classes = useStyles()
    const [openFields, setOpenFields] = useState(true)
    const [responsiveQuery] = useState(true)
    const { filters, facets } = useProductsState()
    const market = useMarketContext()
    const priceListID = usePriceListIDContext()
    const priceListName = priceListID !== 'g' && market.priceList && market.priceList[priceListID] && market.priceList[priceListID].name
    const { store } = useSelectedStore()
    const { parsed, addParam } = AddParam()
    const { isAnonymous } = useUserData()
    const parsedFacetFilters = Object.keys(parsed).filter(f => facets.map(f => f.field).includes(f)).map(fa => parsed[fa].split(',').map(f => { const facet = facets.find(t => t.field === fa); return { id: f, field: fa, name: market[facet.index] && market[facet.index][f] && market[facet.index][f][facet.indexField] } })).flat()
    const showFilters = parsed.showFilters

    return (
        <div>
            {!showFilters && (!minw1024) &&
                <div className={classes.filtersBar}>

                    {/*{!minw1024 &&*/}
                    {/*        <img alt={'F'} src={imgurWebp(filterIcon)} />*/}
                    {/*        <Typography className={classes.barTitle}>FILTRAR*/}
                    {/*            {!!parsedFacetFilters.length && <span style={{*/}
                    {/*                background: 'gray',*/}
                    {/*                color: 'white',*/}
                    {/*                borderRadius: '50%',*/}
                    {/*                width: '1.5em',*/}
                    {/*                paddingLeft: '2px',*/}
                    {/*                display: 'inline-grid',*/}
                    {/*            }}>{parsedFacetFilters.length}</span>}</Typography>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {store?.storeName && !(config.show_prices_only_validated_store && isAnonymous) ?
                        <>

                        </> :
                        priceListName && !(config.show_prices_only_validated_store && isAnonymous) &&
                        <div className={classes.priceListName}><Typography color="textSecondary">{priceListName}</Typography></div>
                    }
                    {!minw1024 &&
                        <div className={classes.filterBarFloatRight} >
                            <div style={{ overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'nowrap', marginLeft: '5px', display: responsiveQuery > minw400 && responsiveQuery < minw1024 ? 'flex' : 'block' }}>
                                {parsedFacetFilters.map((f) =>
                                    <Button variant="contained"
                                        color="primary"
                                        endIcon={<FontAwesomeIcon icon={faTimes}
                                            style={{ fontSize: !minw450 && '10px' }} />}
                                        key={f.id}
                                        onClick={() => {
                                            addParam({ [f.field]: [...(parsed[f.field] ? parsed[f.field].split(',').filter(j => j !== f.id) : [])].join(','), showFilters: '' },
                                                { replace: true }); setOpenFields(!openFields)
                                        }}
                                        style={{ margin: minw450 ? '5px 10px 0 0' : '5px 5px 0 0', fontSize: !minw450 && '10px' }}
                                    >
                                        {f.name}
                                    </Button>
                                )}
                            </div>
                            <div>
                                {/* <img alt={'F'} src={filterIcon} /> */}
                                <Typography className={classes.barTitle}
                                    onClick={() => addParam('showFilters', 'true')}>FILTRAR</Typography>
                            </div>
                        </div>
                    }
                </div>
            }
            {showFilters && !minw1024 &&
                <div className={classes.filtersBack}
                    onClick={() => addParam('showFilters', '')}>
                    <div className={classes.filters}
                        onClick={e => e.stopPropagation()}>
                        {!!parsedFacetFilters.length && <Button color="secondary"
                            style={{ 'float': 'right', 'marginRight': '1rem' }}
                            endIcon={<FontAwesomeIcon icon={faTimes} />}
                            onClick={() => addParam({ ...(parsedFacetFilters.reduce((p, a) => ({ ...p, [a.field]: '' }), {})), showFilters: '' }, { replace: true })}>Borrar Filtros</Button>}
                        <BackButton />
                        {parsedFacetFilters.map((f) =>
                            <Button variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<FontAwesomeIcon icon={faTimes} />}
                                key={f.id}
                                onClick={() => { addParam({ [f.field]: [...(parsed[f.field] ? parsed[f.field].split(',').filter(j => j !== f.id) : [])].join(','), showFilters: '' }, { replace: true }); setOpenFields(!openFields) }}>{f.name}</Button>)}
                        {filters && Object.keys(filters).map((f) => {
                            const facet = facets.find(fa => fa.field === f); return <FilterIndex key={f}
                                {...{ facet }}
                                hits={filters[f]}
                                classes={classes}
                                field={facet.field}
                                index={facet.index}
                                name={facet.name}
                                indexField={facet.indexField} />
                        })}
                    </div>
                </div>
            }
            {minw1024 &&
                <div className={classes.filters}
                    onClick={e => e.stopPropagation()}>
                    {/*{['g', 'd'].includes(priceListID) &&*/}
                    {/*    <>*/}
                    {/*        <Typography className={classes.sliderTitle}>Rango de precios</Typography>*/}
                    {/*        <PriceSlider />*/}
                    {/*    </>*/}
                    {/*}*/}
                    {!!parsedFacetFilters.length && <Button color="secondary"
                        style={{ 'float': 'right', 'marginRight': '1rem' }}
                        endIcon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={() => addParam({ ...(parsedFacetFilters.reduce((p, a) => ({ ...p, [a.field]: '' }), {})), showFilters: '' }, { replace: true })}>Borrar Filtros</Button>}
                    {parsedFacetFilters.map((f) =>

                        <StyledChip
                            variant='outlined'
                            key={f.id}
                            label={f.name}
                            onDelete={() => addParam({ [f.field]: [...(parsed[f.field] ? parsed[f.field].split(',').filter(j => j !== f.id) : [])].join(','), showFilters: '' }, { replace: true })}
                        />
                    )}
                    {filters && Object.keys(filters).map((f) => {
                        const facet = facets.find(fa => fa.field === f); return <FilterIndex key={f}
                            parsedFacetFilters={parsedFacetFilters}
                            {...{ facet }}
                            hits={filters[f]}
                            classes={classes}
                            field={facet.field}
                            index={facet.index}
                            name={facet.name}
                            indexField={facet.indexField} />
                    })}
                </div>
            }
        </div>
    )
}


function FilterIndex({ facet: { name, indexField, index, field, imageField, sortingField = 'ranking' }, hits, classes, parsedFacetFilters }) {
    const market = useMarketContext()
    const matches = useMediaQuery('(min-width:1024px)')
    const { addParam, parsed } = AddParam()
    const priceListID = usePriceListIDContext()
    const { store } = useSelectedStore()
    const [filtroBuscador, setFiltroBuscador] = useState('')
    const [open] = useState(true)
    const handleChange = (e) => {

        setFiltroBuscador(e.target.value)
    }
    const [sort, setSort] = useState(matches ? 10 : 1000)

    useEffect(() => {
        if (matches)
            setSort(10)
        else
            setSort(1000)
    }, [matches])

    const filteredItems = market?.[index] && matchSorter(Object.keys(hits).filter(a => market[index][a])?.reduce((p, a) => ([...p, market[index][a]]), []).sort((a, b) => ((b?.discounts?.[priceListID] || 0) * 1000 - (a?.discounts?.[priceListID] || 0) * 1000) + (typeof a[sortingField] == 'string' ? a[sortingField]?.localeCompare(b[sortingField] || '') : a[sortingField] - b[sortingField])), `${filtroBuscador}`, { keys: ['name'], baseSort: (a, b) => (a.index < b.index ? -1 : 1) }).splice(0, sort)
    return <>

        {market?.[index] && !matches &&

            <Accordion>
                <AccordionSummary
                    className={classes.mainAccordion}
                    expandIcon={<ExpandMoreIcon />}
                >
                    {/*<Typography>{field.name}</Typography>*/}
                    <Typography variant="h3"
                        component={'h3'}>{name}</Typography>
                    {/*<Typography>Tocar para ampliar</Typography>*/}
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                    <Input
                        className={classes.searcherBrands}
                        onChange={handleChange} />

                    {matchSorter(filteredItems, `${filtroBuscador}`, { keys: ['name'], baseSort: (a, b) => (a.index < b.index ? -1 : 1) }).splice(0, sort).map(h =>
                        <div key={h.objectID}><div className={classes.filterOption}
                            onClick={() => addParam({ [field]: [...(parsed[field] ? parsed[field].split(',').filter(j => j !== h.objectID) : []), h.objectID].join(','), showFilters: '' }, { replace: true })}>
                            {imageField && h[imageField] && <img src={imgurWebp(h[imageField])}
                                alt={h[indexField]}
                                className={classes.filterImage} />}
                            <Typography component={'span'}>{h[indexField]}</Typography>
                            {h?.discounts?.[priceListID] && <Typography component={'span'}
                                className={classes.filterDiscount}> {h?.discounts?.[priceListID] * 100}% OFF</Typography>}
                        </div></div>)}
                </AccordionDetails>
            </Accordion>
        }
        {matches && market?.[index] &&
            <Box>
                {filteredItems &&
                    <>
                        <Box style={{ height: '100%' }}
                        >
                            {/*<Typography>{field.name}</Typography>*/}
                            <Box className={classes.filtersBox}>
                                <Typography variant="h3"
                                    component={'h3'}
                                    style={{ height: 70, marginTop: 20, paddingTop: store?.storeName && 45 }} >{name}</Typography>
                                <Input
                                    className={classes.searcherBrands}
                                    variant='outlined'
                                    onChange={handleChange}
                                    placeholder='Buscar'
                                >
                                </Input>
                            </Box>
                            <Box>

                                {filteredItems?.filter(h => !parsedFacetFilters.filter(item => item.id === h.objectID).length).map(h =>
                                    <div key={h.objectID}><div className={classes.filterOption}
                                        onClick={() => addParam({ [field]: [...(parsed[field] ? parsed[field].split(',').filter(j => j !== h.objectID) : []), h.objectID].join(','), showFilters: '' }, { replace: true })}>
                                        {imageField && h[imageField] && <img src={imgurWebp(h[imageField])}
                                            alt={h[indexField]}
                                            className={classes.filterImage} />}
                                        <Typography component={'span'}>{h[indexField]}</Typography>
                                        {h?.discounts?.[priceListID] && <Typography component={'span'}
                                            className={classes.filterDiscount}> {floor(h?.discounts?.[priceListID] * 100)}% OFF</Typography>}
                                    </div></div>)}
                                {open && <>{sort === 10 ? <p className={classes.verMas}
                                    onClick={() => setSort(1000)}>Ver más</p> : <p className={classes.verMas}
                                        onClick={() => setSort(10)}>Ver Menos</p>}</>}
                            </Box>
                        </Box>
                    </>
                }
            </Box>
        }
    </>
}


function BackButton() {
    const { addParam } = AddParam()


    return <div><Button onClick={() => addParam('showFilters', '')}
        style={{ color: 'unset', fontSize: '1.5rem', marginBottom: '1rem' }}><FontAwesomeIcon icon={faArrowLeft} /></Button></div>
}


//Object.keys(hits).filter(a => market[index][a]).sort((a , b)=> (market[index][a].ranking) - (market[index][b].ranking))
//

const StyledChip = withStyles({
    root: {
        borderColor: '#C3E6E6',
        backgroundColor: '#C3E6E6',
        margin: 2,
    },
    label: {
        color: 'black',
        fontWeight: 'bold'
    },
    deleteIcon: {
        color: 'black'
    }
})(Chip)
