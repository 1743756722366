import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  listContainerWrap: {
    display: 'flex',

    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      flexDirection: 'column',
      maxWidth: 600,
    },
  },
  container: {
    position: 'sticky',
    maxHeight: 200,
    top: 0,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      maxHeight: '100%',
    },
  },
  titleDetailWrap: {
    backgroundColor: '#fff',
  },
  listNameStore: {
    fontSize: '1.5rem',
    margin: 0,
    fontWeight: 'bold',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  listNameValidatedMessage: {
    padding: '0rem .5rem',
    color: '#8EBC38',
    fontSize: '1rem',
    borderRadius: 4,
    fontWeight: 'bold',
    maxWidth: 400,
    margin: 0,
  },
  errorValidated: {
    maxWidth: 400,
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  listBox: {
    padding: '.5rem .8rem',
    [theme.breakpoints.down('sm')]: {
      padding: '.5rem .8rem',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: ' -4px 9px 5px 1px rgba(227,220,220,0.78)',
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  listAddress: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: ' -4px 9px 5px 1px rgba(227,220,220,0.78)',
    backgroundColor: '#fff',
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listMap: {
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'space-between',
    boxShadow: ' -4px 9px 5px 1px rgba(227,220,220,0.78)',
    backgroundColor: '#fff',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemsInfoShipping: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  listItems: {
    minWidth: 100,
    maxWidth: 1200,
  },
  listItemsName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: 100,
    maxWidth: 1200,
  },
  listItemsInfo: {
    textAlign: 'right',
  },
  titleDetail: {
    padding: '.5rem 1rem',
  },
  listItemButtonInfo: {
    border: '1px solid #ccc',
    marginLeft: '1rem',
    padding: '.2rem .5rem',
  },
  listItemButtonLink: {
    textDecoration: 'none',
  },
  buttonRepetirPedido: {
    margin: '.5rem',
  },
  listSecondaryBox: {
    maxWidth: 1200,
  },
}))
